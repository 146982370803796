.login-page {
  &__mfa-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .box {
      align-self: center;
      justify-self: center;

      width: 100%;
      height: 100%;

      @include screenSize(tablet) {
        width: rem(480);
        height: auto;
      }
    }

    &--yolo-otp {
      p {
        font-weight: 700;
        font-size: rem(20);
        line-height: rem(28);
        padding: 0 rem(40);
        text-align: center;
      }
    }

    &__error {
      font-weight: 300;
      font-size: rem(14);
      line-height: rem(20);
      text-align: center;
      color: var(--alert-main);
    }
  }
}
