@import '@ytl/common-web/dist/styles/base/typo';

html,
body,
#root {
  height: 100%;
}

.login-page {
  width: 100%;
  height: 100%;
  z-index: 1;

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: rem(0);
    gap: rem(40);
    justify-content: center;
    flex-wrap: wrap;

    @include screenSize(tablet) {
      flex-wrap: nowrap;
      padding: rem(40);
    }
  }

  &__container {
    flex: 1 1 50%;
    max-width: rem(550);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__box {
      padding: rem(40);
      background: $color-white;
      border-radius: rem(16);
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: rem(490);
      height: fit-content;
      gap: rem(24);

      h3 {
        @extend %headings-l3;
        margin: 0;
      }

      p {
        margin: 0;
      }

      &__inputs {
        .form-input {
          margin-bottom: rem(16);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &__register-link {
        font-size: rem(14);
      }

      &--error-view {
        gap: rem(16);

        > p {
          @extend %body-body6;
        }

        > button {
          margin-top: rem(24);
        }
      }
    }

    .button {
      &.button--outlined {
        border-color: var(--button-highlight-color);
      }
    }

    &__back-button {
      margin-top: rem(-16);
    }

    &__page-loader {
      width: 100%;
      min-height: rem(400);
      display: flex;
      justify-content: center;
      align-items: center;

      .loader {
        height: rem(40);

        svg {
          left: rem(-20);
          top: rem(-20);
        }
      }
    }

    .button--alternate-login {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(8);
      width: 100%;
      text-decoration: none;

      svg {
        width: rem(24);
        height: rem(24);
      }
    }

    &__forgot-password-container {
      display: flex;
      justify-content: flex-start;

      .button {
        font-size: rem(14);
        font-weight: 400;
        padding: 0;

        color: var(--forgotten-password-color);
      }
    }

    &__recaptcha {
      margin-bottom: rem(24);
      display: flex;
      justify-content: center;

      @include screenSize(midres) {
        margin-top: rem(12 * calc(410 / 300));
        margin-bottom: rem(24 * calc(410 / 300));
        transform: scale(calc(410 / 300));
      }
    }

    &__error {
      @extend %body-body6;
      color: $color-alert-main;
      margin: rem(-8) 0 0 0;

      a {
        font-size: inherit;
        color: inherit;
        font-weight: 400;
      }
    }

    &__divider {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      position: relative;

      span {
        @extend %body-body3;
        color: $color-grey-300;
        padding: 0 rem(16);
        background: $color-white;
        position: relative;
      }

      &::before {
        content: '';
        width: 100%;
        height: 1px;
        background: $color-grey-300;
        position: absolute;
        left: 0;
        right: 0;
      }
    }

    &__icon-result {
      svg {
        display: block;
        margin-bottom: rem(16);
      }

      span {
        @extend %headings-l3;
      }
    }

    &__result-desc {
      @extend %body-body6;
      color: $color_navyblue;
      margin: rem(16) 0 rem(40) 0;

      button {
        display: block;
        margin: rem(16) 0 0 0;
      }
    }

    &__business-login {
      background: var(--warning-light);
      padding: rem(16);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(8);

      > div {
        flex: 1;
      }

      h5 {
        font-weight: 700;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--navyblue);
        margin: 0;
      }
      p {
        font-weight: 300;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--navyblue);
        margin: 0;
      }
      a {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(20);
        text-decoration: underline;
        color: var(--navyblue);
      }
    }
  }

  &__sms-code-input-container {
    .form-input {
      margin-bottom: 0;
    }
  }

  &__phone-number-input-container {
    .form-input {
      position: relative;
      margin-bottom: 0;

      &::after {
        font-style: normal;
        font-weight: 300;
        font-size: rem(16);
        line-height: rem(20);

        position: absolute;
        content: '+36';
        color: $color-grey-500;
        bottom: rem(14);
        left: rem(11);
      }

      input {
        padding-left: rem(38);
      }
    }
  }
}

.login-page-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
  display: flex;

  &__backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($color-navyblue, 0.8);
  }

  &__content-wrapper {
  }
}
