.login-page {
  &__hero {
    flex: 1 1 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--navyblue);
    padding: rem(40);

    @include screenSize(tablet) {
      background: transparent;
      padding: 0;
    }

    h1 {
      @extend %headings-l1;
      color: $color_white;
      margin: 0 0 rem(32) 0;
    }

    p {
      @extend %body-body5;
      color: $color_white;
      margin: 0;
    }

    a {
      color: inherit;
      font-weight: 400;
    }

    .button {
      margin: rem(40) 0 0 0;
      text-decoration: none;
      display: inline-block;
      color: $color_lime;
      border-color: $color_lime;
    }
  }
}
