html.ytl--app {
  background: var(--bg-color-webview);

  .frame {
    &__header {
      display: none;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .login-page {
    background: transparent;

    &__wrapper {
      align-items: center;
    }

    &__hero {
      display: none;
    }

    &__container {
      &__box {
        background: transparent;
        gap: rem(16);

        &__password-hint {
          display: block;
          @extend %body-body6;
          margin-top: rem(-8);
          color: var(--grey-500);
        }
      }

      &__back-button {
        margin-top: rem(-8);
      }

      &__business-login {
        display: none;
      }
    }

    &__container__divider span {
      background: var(--bg-color-webview);
    }
  }
}
