html {
  background: var(--bg-color-register-sd);

  @include screenSize(tablet) {
    background: var(--bg-color-register);
  }

  .yui--mfa {
    background: var(--bg-color);

    @include screenSize(tablet) {
      background: var(--bg-color);
    }
  }
}

.frame {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  height: fit-content;
  min-height: 100vh;

  &::before {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(350deg, var(--lime) 550px, transparent 551px);
  }

  &::after {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: var(--lime);
  }

  @include screenSize(tablet) {
    &::before {
      content: ' ';
      height: 1200px;
    }

    &::after {
      content: ' ';
      top: 1200px;
    }
  }

  @include screenSize(midres) {
    &::before {
      content: ' ';
      height: 1100px;
    }

    &::after {
      content: ' ';
      top: 1100px;
    }
  }

  &--mfa {
    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  &__yui-header {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__header {
    width: 100%;
    z-index: 10;

    @include screenSize(desktop) {
      max-width: rem(1200);
      padding: 0 rem(24);
    }

    @include screenSize(highres) {
      max-width: rem(1600);
    }
  }

  &__content {
    justify-content: center;
    display: flex;
    width: 100%;
    max-width: rem(1200);
    height: 100vh;

    @include screenSize(highres) {
      max-width: rem(1600);
    }
  }
}
